@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BodyFont';
  src: url('./assets/fonts/Louis\ George\ Cafe.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeroText';
  src: url('./assets/fonts/Agraham.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HeaderText';
  src: url('./assets/fonts/Agraham.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TitleText';
  src: url('./assets/fonts/gang.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SomeText';
  src: url('./assets/fonts/water.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.someText {
  font-family: 'SomeText', sans-serif;
}

body {
  font-family: 'BodyFont', sans-serif;
}

.heroText {
  font-family: 'HeroText', sans-serif;
}

.headerText {
  font-family: 'HeaderText', sans-serif;
}

.titleText {
  font-family: 'TitleText', sans-serif;
}


/* Navbar scroll effect */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}

.header-bg {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}

.header::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  z-index: -1;
}

.header::after {
  content: "";
  top: 0;
  left: -100%;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.9),
    transparent
  );
  transition: 0.8s;
}

.header:hover::after {
  left: 100%;
}
