@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;1,400&display=swap");

@mixin mQ($rem) {
  @media screen and (min-width: $rem) {
    @content;
  }
}

h2 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.card {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    transition: 0.6s;
    z-index: 0;
    background-color: rgb(255, 115, 0);
  }

  &:hover {
    box-shadow: 0.063rem 0.063rem 1.25rem 0.375rem rgb(0 0 0 / 53%);
  }

  &:nth-child(1)::before {
    bottom: 0;
    right: 0;
    clip-path: circle(calc(6.25rem + 7.5vw) at 100% 100%);
  }

  &:nth-child(2)::before {
    bottom: 0;
    left: 0;
    clip-path: circle(calc(6.25rem + 7.5vw) at 0% 100%);
  }

  &:nth-child(3)::before {
    top: 0;
    right: 0;
    clip-path: circle(calc(6.25rem + 7.5vw) at 100% 0%);
  }

  &:nth-child(4)::before {
    top: 0;
    left: 0;
    clip-path: circle(calc(6.25rem + 7.5vw) at 0% 0%);
  }

  p {
    transition: 0.8s;
  }
}

.card:hover::before {
  clip-path: circle(110vw at 100% 100%);
}

.card:hover h2 {
  color: rgb(255, 217, 0);
}

.card:hover p {
  color: #fff;
}

.circle {
  @include mQ(62.5rem) {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.card:nth-child(1) .circle {
  background: url("https://images.pexels.com/photos/38326/pexels-photo-38326.jpeg?auto=compress&cs=tinysrgb&w=600") no-repeat 10% 30%  / cover;
  bottom: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 100%);
}

.card:nth-child(2) .circle {
  background: url("https://images.pexels.com/photos/7846477/pexels-photo-7846477.jpeg?auto=compress&cs=tinysrgb&w=600") no-repeat 50% 50% / cover;
  bottom: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 100%);
}

.card:nth-child(3) .circle {
  background: url("https://images.pexels.com/photos/5414576/pexels-photo-5414576.jpeg?auto=compress&cs=tinysrgb&w=600") no-repeat 50% 50% / cover;
  top: 0;
  right: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 100% 0%);
}

.card:nth-child(4) .circle {
  background: url("https://images.pexels.com/photos/3574440/pexels-photo-3574440.jpeg?auto=compress&cs=tinysrgb&w=600") no-repeat 50% 50% / cover;
  top: 0;
  left: 0;
  clip-path: circle(calc(6.25rem + 7.5vw) at 0% 0%);
}